@import "../../../Color.scss";

.Container {
  display: flex;
  position: relative;
}
.Label {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 10px 10px;
  transition: all 200ms ease-out;
  font-size: 14px;
  letter-spacing: 1.5px;
  color: rgba(0, 0, 0, 0.9);
}
.LabelFocus {
  top: 0;
  bottom: auto;
  font-size: 10px;
}

.Input {
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 10px 10px 0px 10px;
  font-size: 14px;
  width: 100%;
  letter-spacing: 1px;
  color: $colorTextInput;
  background-color: $colorInput;
  box-shadow: inset 0px 0px 7px 0.1px rgba(0, 0, 0, 0.2);
}

.InputText {
  @extend .Input;
  min-height: 46px;
}

.InputTextarea {
  @extend .Input;
  min-height: 160px;
  width: 100%;
  padding: 25px 10px 5px 10px;
}

.ContainerCheckbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.Checkbox {
  position: absolute;
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  z-index: -1;
  opacity: 0;
  & + label {
    display: inline-flex;
    align-items: flex-start;
    user-select: none;
    transition: all 200ms ease-out;
    cursor: pointer;
    &::before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      flex-grow: 0;
      background-color: $colorInput;
      box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.25em;
      margin-right: 15px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      transition: all 200ms ease-out;
    }
  }

  // стили при активном checkbox*
  &:checked {
    & + label {
      &::before {
        border-color: $colorPrimary;
        background-color: $colorPrimary;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      }
    }
  }

  // стили при наведении курсора на checkbox
  &:not(:disabled):not(:checked) {
    & + label {
      &:hover {
        &::before {
          border-color: $colorPrimary;
        }
      }
    }
  }

  // стили для активного состояния чекбокса (при нажатии на него)
  &:not(:disabled):active {
    & + label {
      &::before {
        background-color: $colorPrimary;
        border-color: $colorPrimary;
      }
    }
  }

  // стили для чекбокса, находящегося в фокусе
  &:focus {
    & + label {
      &::before {
        box-shadow: 0 0 0 0.2rem rgba($colorPrimary, 0.25);
      }
    }

    // стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked
    &:not(:checked) {
      & + label {
        &::before {
          border-color: $colorPrimary;
        }
      }
    }
  }

  // стили для чекбокса, находящегося в состоянии disabled
  &:disabled {
    & + label {
      &::before {
        background-color: $colorPrimary;
      }
    }
  }
}
