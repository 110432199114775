@import "../../Color.scss";
.Container {
  //
}

.Footer {
  position: relative;
  display: flex;
  height: 87px;
  border-left: 2px solid $colorBorder;
  overflow-x: visible;
  z-index: 1;
}

.Logo {
  height: 88px;
  width: 88px;
  background-color: #ccff33;
  border-right: 2px solid $colorBorder;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.Content {
  display: flex;
  width: 100%;
  //align-items: center;
  //margin: auto;
}
@media (max-width: 860px) {
  .Container {
    margin: 0 !important;
    padding: 0 !important;
  }
  .Footer {
    border-left: none;
  }
}
