@font-face {
  src: url("Raleway-Black.ttf") format("truetype");
  font-family: "Raleway";
  font-weight: 900;
  font-style: normal;
}
@font-face {
  src: url("Raleway-BlackItalic.ttf") format("truetype");
  font-family: "Raleway";
  font-weight: 900;
  font-style: italic;
}

@font-face {
  src: url("Raleway-Bold.ttf") format("truetype");
  font-family: "Raleway";
  font-weight: 700;
  font-style: normal;
}

@font-face {
  src: url("Raleway-BoldItalic.ttf") format("truetype");
  font-family: "Raleway";
  font-weight: 700;
  font-style: italic;
}

@font-face {
  src: url("Raleway-ExtraBold.ttf") format("truetype");
  font-family: "Raleway";
  font-weight: 800;
  font-style: normal;
}
@font-face {
  src: url("Raleway-ExtraBoldItalic.ttf") format("truetype");
  font-family: "Raleway";
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Raleway";
  src: url("Raleway-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Raleway";
  src: url("Raleway-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  src: url("Raleway-Italic.ttf") format("truetype");
  font-family: "Raleway";
  font-weight: 400;
  font-style: italic;
}
@font-face {
  src: url("Raleway-Light.ttf") format("truetype");
  font-family: "Raleway";
  font-weight: 300;
  font-style: normal;
}
@font-face {
  src: url("Raleway-LightItalic.ttf") format("truetype");
  font-family: "Raleway";
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url("Raleway-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("Raleway-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url("Raleway-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("Raleway-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("Raleway-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url("Raleway-Thin.ttf") format("truetype");
  font-weight: 1;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("Raleway-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("Raleway-ThinItalic.ttf") format("truetype");
  font-weight: 1;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url("Raleway-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
