.Section {
  position: relative;
  display: flex;
  border-left: 2px solid #2c0e33;
  max-width: 100%;
}

.Title {
  border-right: 2px solid #2c0e33;
  min-width: 88px;
  width: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  h2 {
    margin: 0;
    padding: 0;
    transform: rotate(-90deg);
    font-size: 72px;
    text-transform: uppercase;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    position: absolute;
  }
}

.Content {
  padding: 30px 45px;
  flex-grow: 1;
  max-width: 100%;
  box-sizing: border-box;
}

.HiddenLine {
  border-color: transparent;
}

@media (max-width: 860px) {
  .Title {
    display: none;
  }
  .Content {
    padding: 10px;
  }
  .Section {
    border: none;
  }
}
