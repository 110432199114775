@import "../../Color.scss";

.Container {
  position: relative;
  margin: auto;
}

.FormProject {
  padding: 55px 80px 70px 80px;
  border-radius: 25px;
}

.FormStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.FormStatusMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  z-index: -1;
  opacity: 0;
  transition: all 200ms ease-out;
}

.FormStatusCircle {
  border: 3px solid #3300cc;
  border-radius: 100%;
  height: 170px;
  width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FormContent {
  display: flex;
  flex-direction: column;
  transition: all 200ms ease-out;
}

.Title {
  font-size: 48px;
  font-weight: bold;
  line-height: 1.458;
  color: #ebeff4;
  margin: 0;
  padding: 0;
}

.CheckboxLabel {
  color: $colorPrimary;
  font-size: 12px;
  line-height: 1.667;
  font-weight: 600;
  letter-spacing: 1px;
}

@media (max-width: 860px) {
  .FormProject {
    padding: 0;
    border-radius: 25px;
  }
}
