@import "../../Color.scss";

.SliderBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  background-position-x: left;
  background-position-y: top;
  img {
    object-fit: cover;
    object-position: top;
    opacity: 0.2;
  }
}
.SliderBackPattern {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  transform-origin: top;
  transition: all 200ms ease;
  transition-delay: 0 200ms;
  img {
    width: 100%;
    object-fit: contain;
    transform: scale(2);
  }
}
.SliderItemWrapper {
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 700px;
  margin: 0 30px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &:target,
  &:active {
    .SliderBackPattern {
      opacity: 1;
    }
  }
}

.SliderItem {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  background: rgba(#92d3f9, 0.5);
  overflow: hidden;
  transition: all 200ms ease-out;
  z-index: 1;
  box-sizing: border-box;
  * {
    transition: all 300ms ease-out;
  }

  &:hover,
  &:target,
  &:active {
  }
}
.SliderItemMore {
  @extend .SliderItem;
  border-radius: 0;
  background: #363798;
  .Content {
    padding: 0;
  }
  .ContentText {
    top: 0;
    bottom: 0;
  }
  .TextScroll {
    //
  }
  .BoxText {
    margin-bottom: 20px;
  }
}

.Number {
  position: relative;
  flex-grow: 0;
  height: 120px;
}
.NumberValue {
  position: absolute;
  top: 33px;
  left: 83px;
  transform: translateX(-50%);
  right: auto;

  font-size: 90px;
  font-family: "Misto";
  line-height: 0.625;
  color: #ffffff;
}
.NumberParent {
  position: absolute;
  top: -10px;
  height: 120px;
  width: 120px;
  background-size: cover;
  left: 50%;
}
.NumberParent1 {
  @extend .NumberParent;
  background-image: url("../../img/slider_item_title_1.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 90px;
  margin-top: 25px;
  transform: translateX(0);
  left: auto;
  right: 0;
}

.TitleWrapper {
  display: flex;
  align-items: flex-end;
  flex-grow: 0;
}
.Title {
  position: relative;
  font-size: 18px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.667;
  padding: 0 16px 30px 32px;
  z-index: 1;
  border-color: $colorSecondary;

  &::before {
    content: "";
    position: absolute;
    background-image: url("../../img/slider_item_bg.png");
    background-position: top;
    top: -80px;
    left: 0;
    right: 0;
    height: 200%;
    z-index: -1;
    transition: all 200ms ease-in;
    opacity: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: -500px;
    left: 0;
    width: 16px;
    height: 100%;

    background-color: #cbff36;
    transition: all 500ms ease;
    transition-delay: 250ms;
    top: 0;
    opacity: 1;
  }
}
.Content {
  display: flex;
  position: relative;
  font-size: 18px;
  font-family: "Raleway";
  line-height: 1.667;
  margin-bottom: 0;

  display: flex;
  padding-top: 80px;
  padding-bottom: 115px;
  background-image: url("../../img/slider_item_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  flex-grow: 1;
}
.ContentText {
  position: absolute;
  top: 0;
  right: 16px;
  left: 16px;
  bottom: 115px;
  overflow: hidden;
}

.Actions {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px 0 20px 0;
  z-index: 2;
  pointer-events: all;

  button {
    background-color: #cbff36;
    color: #3300cc;
  }
}

.Close {
  background: none;
  border: none;
  outline: none;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  margin: 22px 0;
  opacity: 1;

  &:hover,
  &:target,
  &:active {
    transform: scale(1.2);
  }
}

.SliderBackgroundAfter {
  position: absolute;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 100px;
}

.TitleMore {
  position: absolute;
  margin: 0 158px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.667;
  z-index: 1;
  padding: 28px 0 0 16px;
  border-left: 16px solid #cbff36;
  opacity: 0;
  min-height: 70px;
  top: -150px;
  transition-delay: 200ms 0;
}

.BoxText {
  height: 100%;
}

.TextScroll {
  padding-right: 0.5rem;
  overflow-y: scroll;
  scrollbar-color: #cbff36 transparent;

  &::-webkit-scrollbar-button {
    background-image: url("");
    background-repeat: no-repeat;
    width: 5px;
    height: 0px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-color: #cbff36;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #d7ff67;
  }

  &::-webkit-resizer {
    background-image: url("");
    background-repeat: no-repeat;
    width: 12px;
    height: 0px;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &.text-scroll-absolute {
    position: absolute;
    height: 100%;

    @media (max-width: 600px) {
      position: relative;
      margin: 1rem 0;
      height: 250px;
    }
  }
}
.ButtonFullscreen {
  position: absolute;
  top: -20px;
  right: 0;
  padding: 5px;
  z-index: 99999;
  transition: all 200ms;

  &:hover,
  &:target,
  &:focus {
    svg {
      color: #cbff36;
      transform: scale(1.25);
    }
  }
}
