@import "../../Color.scss";
.Header {
  position: relative;
  height: 87px;
  max-width: 100%;
  border-top: 2px solid $colorBorder;
  border-bottom: 2px solid $colorBorder;
  border-left: 2px solid $colorBorder;
  display: flex;
  align-items: center;
  margin-top: 18px;

  &::after {
    content: "";
    top: -2px;
    right: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border-top: 2px solid $colorBorder;
    border-bottom: 2px solid $colorBorder;
    transform: translateX(100%);
  }
}

.Logo {
  height: 88px;
  width: 88px;
  background-color: #ccff33;
  border-right: 2px solid $colorBorder;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.Title {
  flex-grow: 1;
  padding: 0 95px;
  h1 {
    font-family: "Dited";
    font-size: 24px;
    margin: 0;
    color: $colorPrimary;
    font-weight: 500;
    letter-spacing: 1px;
  }
}

.Menu {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MenuItem {
  margin-left: 40px;
}

.ForDesktop {
  //
}
.ForMobile {
  display: none;
}

@media (max-width: 860px) {
  .ForDesktop {
    display: none;
  }
  .ForMobile {
    display: flex;
  }

  .Container {
    margin: 0 !important;
    padding: 0 !important;
  }

  .Header {
    margin-top: 0;
    border-top: none;
    border-left: none;
  }
}
