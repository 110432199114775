@import "../../Color.scss";

$borderRadius: 20px;

.Wrapper {
  position: relative;
  margin: auto;
  width: 360px;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;

  .ImgFormFeedbackBg1 {
    position: absolute;
    transition: all 500ms ease-in;
    right: 0;
    top: 0;
  }
  .ImgFormFeedbackBg2 {
    position: absolute;
    transition: all 500ms ease-in;
    top: -800px;
    left: -800px;
  }
  .ImgFormFeedbackBg3 {
    position: absolute;
    transition: all 500ms ease-in;
    top: -800px;
    bottom: auto;
    right: -800px;
  }
}
.WrapperToForm {
  .ImgFormFeedbackBg1 {
    position: absolute;
    right: -149px;
    top: 290px;
  }
  .ImgFormFeedbackBg2 {
    position: absolute;
    top: 0;
    left: -210px;
  }
  .ImgFormFeedbackBg3 {
    position: absolute;
    top: 290px;
    right: -118px;
  }
}

.WrapperButton {
  position: relative;
  top: 0;
  right: 0;
  transition: all 300ms ease-in;
}
.WrapperForm {
  position: absolute;
  top: 0;
  right: 0;
  transition: all 300ms ease-in;
}

.Container {
  position: relative;
  margin: auto;
}

.FormShadow {
  position: absolute;
  top: 10px;
  left: 8px;
  height: 100%;
  width: 100%;
  border-radius: 50px;
  border: 1px solid #2c0e33 inset;
}
.FormBlur {
  position: absolute;
  content: "";
  background: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  border-radius: $borderRadius;
  z-index: -1;
  background-color: rgba($color: #dddee0, $alpha: 0.5);
  box-shadow: 0px 0px 5px 3px #ffffff inset;
}
.Form {
  position: relative;
  border-radius: $borderRadius;
  padding: 40px 35px 28px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 2;
  box-shadow: 0px 0px 60px 10px #0000002b;
  overflow: hidden;
}
.FormContent {
  display: flex;
  flex-direction: column;
  transition: all 200ms ease-out;
}
.BtnClose {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  outline: none;
}
.FormStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.FormStatusMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  z-index: -1;
  opacity: 0;
  transition: all 200ms ease-out;
}

.Checkbox {
  cursor: pointer;
  position: relative;
  margin: 0 10px 0 0;
  width: 18px;
  height: 18px;
  transform: translate3d(0, 0, 0);
  border-radius: 10px;

  svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #c8ccd4;
    stroke-width: 1.5;
    background: $colorInput;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
    path {
      stroke-dasharray: 60;
      stroke-dashoffset: 0;
    }
    polyline {
      stroke-dasharray: 22;
      stroke-dashoffset: 66;
    }
  }
  &:hover {
    &:before {
      opacity: 1;
    }
    svg {
      stroke: $colorPrimary;
    }
  }
}
.CheckboxActive {
  svg {
    stroke: $colorPrimary;
    path {
      stroke-dashoffset: 60;
      transition: all 0.3s linear;
    }
    polyline {
      stroke-dashoffset: 42;
      transition: all 0.2s linear;
      transition-delay: 0.15s;
    }
  }
}

.CheckboxLabel {
  color: $colorPrimary;
  font-size: 12px;
  line-height: 1.667;
  font-weight: 600;
  letter-spacing: 1px;
}

.Actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.FormButtonReset {
  position: absolute;
  top: 0;
  right: -50px;
  padding: 10px;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  height: auto;
  width: auto;
  transition: all 200ms ease-in;
  &:hover {
    transform: scale(1.3);
  }
}

.FormStatusCircle {
  border: 3px solid #3300cc;
  border-radius: 100%;
  height: 170px;
  width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FormFeedbackButton {
  position: relative;
  height: 200px;
  width: 200px;
  cursor: pointer;
}
.FormFeedbackButtonImg1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: contain;
    object-position: center;
    animation-duration: 10s;
    animation-name: anim;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
.FormFeedbackButtonImg2 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: contain;
    object-position: center;
  }
}
@keyframes anim {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1280px) {
  .Wrapper {
    transform: scale(0.9);
  }
}

@media (max-width: 800px) {
  .Wrapper {
    margin: -15% 0;
    transform: scale(0.7);
  }
}
