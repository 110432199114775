@import url("./font/font.scss");
@import url("./Color.scss");

@import "~react-image-gallery/styles/scss/image-gallery.scss";
body {
  background-color: #ebeff4;
  max-width: 100vw;
  margin: 0;
  overflow-x: hidden;
}
.App {
  min-height: 100vh;
  margin-top: 18px;
  overflow: hidden;
  @media (max-width: 860px) {
    max-width: 100vw;
  }
}
* {
  font-family: "Raleway";
}

button {
  &.image-gallery-left-nav,
  &.image-gallery-right-nav {
    width: 100px;
    padding: 0;
    height: auto;
    &:hover,
    &:target,
    &:focus {
      color: #cbff36;
    }
  }

  &.image-gallery-fullscreen-button {
    width: 28px;
    height: 28px;
    padding: 0;
    margin: 20px;
    svg {
      width: 28px;
      height: 28px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
}

@media (max-width: 860px) {
  .App {
    margin-top: 0;
  }
}
