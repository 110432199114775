@import "../../../Color.scss";

.Container {
  position: relative;
  display: flex;
  &:hover,
  &:target,
  &:focus,
  &:active {
    Button {
      transform: translateY(3px);
    }
    .Shadow {
      content: "";
      position: absolute;
      top: 3px;
      left: 0;
      //transform: scale(0.9);
    }
  }
  * {
    transition: all 150ms;
  }
}

Button {
  position: relative;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #2c0e33;
  font-size: 12px;
  color: #000000;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
  padding: 2px 6px;
  &:hover,
  &:target,
  &:focus,
  &:active {
    color: $colorPrimary;
  }

  &.Small {
    font-style: 12px;
  }
}

.Shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 3px;
  left: 3px;
  background-color: $colorShadow;
  &.Lg {
    top: 7px;
    left: 7px;
  }
}
