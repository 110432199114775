@import "../../../Color.scss";
.ButtonForm {
  background-color: transparent;
  border: 2px solid $colorSecondary;
  border-radius: 50px;
  color: $colorSecondary;
  text-transform: uppercase;
  width: auto;
  height: auto;
  font-size: 20.254px;
  font-family: "Dited";
  font-weight: 400;
  line-height: 1.234;
  letter-spacing: 1px;
  padding: 15px;
  transition: all 200ms ease-in;

  &:hover,
  &:active,
  &:target,
  &:focus {
    color: #ffffff;
    background-color: $colorSecondary;
    color: $colorPrimary;
  }

  &.White {
    border: 2px solid white;
    color: white;
    &:hover,
    &:active,
    &:target,
    &:focus {
      background-color: white;
      color: $colorPrimary;
    }
  }

  &.Primary {
    border: 2px solid white;
    color: $colorPrimary;
    &:hover,
    &:active,
    &:target,
    &:focus {
      background-color: $colorPrimary;
      color: white;
    }
  }
}
