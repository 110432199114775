$colorShadow: #65606a;

$colorPrimary: #3300cc;
$colorPrimaryDark: #281475;
$colorGrey: #65606a;
$colorSecondary: #cbff36;

$colorBorder: #2c0e33;

$paddingSection: 25px;

$colorInput: #ebeff4;
$colorTextInput: #54475f;
