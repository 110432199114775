.Map {
  position: relative;
}
.MapCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 115px;
  width: 115px;
  position: absolute;
  top: 350px;
  left: 150px;
  cursor: pointer;
}
.MapCircleText {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  font-weight: bold;
  color: #8f64f8;
}
.MapCircle1 {
  position: absolute;
  height: 115px;
  width: 115px;
  border-radius: 100%;
  background-color: #ccff33;
  box-shadow: 0px 0px 28px 4px #12121294;
}
.MapCircle2 {
  position: absolute;
  height: 115px;
  width: 115px;
  border-radius: 100%;
  background-color: #ccff33;
  animation-duration: 3s;
  animation-name: c2;
  animation-delay: 0s;
  animation-iteration-count: infinite;
}
.MapCircle3 {
  position: absolute;
  height: 115px;
  width: 115px;
  border-radius: 100%;
  background-color: #ccff33;
  animation-duration: 3s;
  animation-name: c3;
  animation-iteration-count: infinite;
}

@keyframes c2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.435);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.435);
    opacity: 0;
  }
}

@keyframes c3 {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }

  50% {
    transform: scale(1.3635);
    opacity: 0.25;
  }
  100% {
    transform: scale(1.3635);
    opacity: 0;
  }
}

@media (max-width: 860px) {
  .Map {
    transform: scale(0.7);
    margin: -15% 0;
    transform-origin: top;
  }
}
