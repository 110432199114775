@import "../Color.scss";

.AboutSectionText {
  margin-left: 47px;
}
.ContactSectionText {
  margin-left: 47px;
}
.Title {
  //
}
.Text {
  //
}
.ProjectsSectionWrapper {
  position: relative;
  height: 100vh;
  //height: 600px;
  padding-bottom: 50px;
  box-sizing: border-box;
}
.ProjectsSection {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: none;
  width: 500px;
}
.ProjectsSectionPattern {
  position: absolute;
  top: 0;
  height: 100%;
  width: 960px;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.ProjectsSectionPatternLeft {
  @extend .ProjectsSectionPattern;
  left: 0;
  background-image: url("../img/bg_project_pattern_left.png");
  background-position: top left;
}
.ProjectsSectionPatternRight {
  @extend .ProjectsSectionPattern;
  right: 0;
  background-image: url("../img/bg_project_pattern_right.png");
  background-position: top right;
}
.DiscussProject {
  background-image: url("../img/bg_form_discuss_project.png");
  background-size: cover;
  background-position: top;
}

.WrapperForm {
  position: relative;
}

@media (max-width: 860px) {
  .AboutSectionText {
    margin-left: 0;
  }
  .ContactSectionText {
    margin-left: 0;
  }
}
